// import Swiper JS and style
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, EffectFade, Navigation, Pagination])

function initSwiper () {
  return new Swiper('.swiper', {
    slidesPerView: 1,
    speed: 400,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      clickable: true,
      el: '.swiper-pagination',
      type: 'bullets',
    },
  })
}

document.addEventListener('turbolinks:load', initSwiper)
